import React from 'react';

const OtherSkills = () => {
    return (
        <div className='otherSkills'>

            <h3>Autres compétences</h3>

            <div className="list">
                <ul>
                    <li><i className="fas fa-check-square"></i> Anglais courant</li>
                    <li><i className="fas fa-check-square"></i> Github</li>
                    <li><i className="fas fa-check-square"></i> Méthode Agile</li>
                    <li><i className="fas fa-check-square"></i> Docker</li>
                </ul>
                <ul>
                    <li><i className="fas fa-check-square"></i> Figma</li>
                    <li><i className="fas fa-check-square"></i> UI/UX Design</li>
                    <li><i className="fas fa-check-square"></i> SEO</li>
                    <li><i className="fas fa-check-square"></i> Photoshop/GIMP</li>
                </ul>
            </div>

        </div>
    );
};

export default OtherSkills;